// extracted by mini-css-extract-plugin
export var altButtonActiveBg = "#eff4fb";
export var animationDuration = "1000ms";
export var apiHeader = "#f6f6f6";
export var basicWarningBackground = "#fdfdec";
export var basicWarningBorder = "#edf079";
export var bodyBackground = "#f1f1f1";
export var boldWeight = "800";
export var borderColor = "#ddd";
export var breadCrumbsContainer = "i-bond-sales-chart-module--breadCrumbsContainer--b01e7";
export var breakpointLg = "992px";
export var breakpointMd = "768px";
export var breakpointSm = "600px";
export var breakpointXl = "1205px";
export var cardBody = "#555";
export var chartBackdrop = "i-bond-sales-chart-module--chartBackdrop--71e55";
export var chartLegend = "i-bond-sales-chart-module--chartLegend--3ad88";
export var chartStyle = "i-bond-sales-chart-module--chartStyle--294c7";
export var contentContainer = "i-bond-sales-chart-module--contentContainer--9d424";
export var contentSectionBackground = "#fff";
export var darkGreen = "#2e8540";
export var darkGreyBorder = "#979797";
export var darkPink = "#d72d79";
export var darkSlateBlue = "#112e51";
export var ddBorderColor = "#d6d7d9";
export var debtExplainerLightSecondary = "#b699c6";
export var debtExplainerPrimary = "#4a0072";
export var debtExplainerSecondary = "#3b005b";
export var desktopSubNav = "i-bond-sales-chart-module--desktopSubNav--fe01e";
export var disabledButtonBg = "#94beda";
export var dropdownActiveBackground = "#dff2f7";
export var errorBackground = "#fee";
export var errorLinkColor = "#801313";
export var errorTextColor = "#a02323";
export var focus = "#2b71b8";
export var fontBodyCopy = "#666";
export var fontSize_10 = ".625rem";
export var fontSize_12 = ".75rem";
export var fontSize_13 = ".8125rem";
export var fontSize_14 = ".875rem";
export var fontSize_15 = ".9375rem";
export var fontSize_16 = "1rem";
export var fontSize_18 = "1.125rem";
export var fontSize_20 = "1.25rem";
export var fontSize_22 = "1.375rem";
export var fontSize_24 = "1.5rem";
export var fontSize_28 = "1.75rem";
export var fontSize_32 = "2rem";
export var fontSize_36 = "2.25rem";
export var fontSize_40 = "2.5rem";
export var fontSize_48 = "3rem";
export var fontSize_56 = "3.5rem";
export var fontTitle = "#555";
export var headerContainer = "i-bond-sales-chart-module--headerContainer--e3bbe";
export var helpTextColor = "#d93b36";
export var infoBoxBackground = "#f3fafc";
export var infoBoxBorder = "#bce8f1";
export var infoIcon = "#00a5d4";
export var label = "i-bond-sales-chart-module--label--422b6";
export var leftLabel = "i-bond-sales-chart-module--leftLabel--1c033";
export var leftLine = "i-bond-sales-chart-module--leftLine--48cfa";
export var lengendItem = "i-bond-sales-chart-module--lengendItem--7b1ae";
export var lightWeight = "300";
export var line = "i-bond-sales-chart-module--line--ce00a";
export var linkColor = "#2272ce";
export var mainContainer = "i-bond-sales-chart-module--mainContainer--88eb7";
export var mainContent = "i-bond-sales-chart-module--mainContent--4a214";
export var mainWidth = "1140px";
export var mobileSubNav = "i-bond-sales-chart-module--mobileSubNav--3078c";
export var mudAccordion = "i-bond-sales-chart-module--mudAccordion--84b59";
export var paleBg = "#f8f8f8";
export var placeholder = "#a8a8a8";
export var postQuoteBoxAccordionContainer = "i-bond-sales-chart-module--postQuoteBoxAccordionContainer--05007";
export var primary = "#0071bc";
export var primaryDarker = "#205493";
export var primaryGlass = "rgba(0,113,188,.3)";
export var primaryInactive = "rgba(8,114,185,.4)";
export var primaryLightBackground = "#a9ddf3";
export var primaryPale = "#f1f7f8";
export var quoteBoxContent = "i-bond-sales-chart-module--quoteBoxContent--43f42";
export var relatedDatasets = "i-bond-sales-chart-module--relatedDatasets--9f899";
export var relatedDatasetsStyle = "i-bond-sales-chart-module--relatedDatasetsStyle--88940";
export var rightLine = "i-bond-sales-chart-module--rightLine--06036";
export var section = "i-bond-sales-chart-module--section--8c7c2";
export var sectionBorder = "i-bond-sales-chart-module--sectionBorder--00951";
export var sectionHeading = "i-bond-sales-chart-module--sectionHeading--9e6a9";
export var semiBoldWeight = "600";
export var siteContentWidth = "1140px";
export var slateBlue = "#112e51";
export var socialShare = "i-bond-sales-chart-module--socialShare--9d7c4";
export var socialShareContainer = "i-bond-sales-chart-module--socialShareContainer--a5a80";
export var sourceURL = "i-bond-sales-chart-module--sourceURL--b55f3";
export var treasurySavingsBondsExplainerHero = "#b699c6";
export var treasurySavingsBondsExplainerLightSecondary = "#f8f0f9";
export var treasurySavingsBondsExplainerPrimary = "#3b005b";
export var treasurySavingsBondsExplainerSecondary = "#b04abd";
export var visWithCallout = "i-bond-sales-chart-module--visWithCallout--a93e2";
// extracted by mini-css-extract-plugin
export var altButtonActiveBg = "#eff4fb";
export var animationDuration = "1000ms";
export var apiHeader = "#f6f6f6";
export var basicWarningBackground = "#fdfdec";
export var basicWarningBorder = "#edf079";
export var bodyBackground = "#f1f1f1";
export var boldWeight = "800";
export var borderColor = "#ddd";
export var breakpointLg = "992px";
export var breakpointMd = "768px";
export var breakpointSm = "600px";
export var breakpointXl = "1205px";
export var cardBody = "#555";
export var container = "revenue-trends-line-chart-module--container--8a330";
export var contentSectionBackground = "#fff";
export var corpRect = "revenue-trends-line-chart-module--corpRect--3d425";
export var corpRectTooltip = "revenue-trends-line-chart-module--corpRectTooltip--20e55";
export var customsRect = "revenue-trends-line-chart-module--customsRect--ec620";
export var customsRectTooltip = "revenue-trends-line-chart-module--customsRectTooltip--17f07";
export var darkGreen = "#2e8540";
export var darkGreyBorder = "#979797";
export var darkPink = "#d72d79";
export var darkSlateBlue = "#112e51";
export var ddBorderColor = "#d6d7d9";
export var debtExplainerLightSecondary = "#b699c6";
export var debtExplainerPrimary = "#4a0072";
export var debtExplainerSecondary = "#3b005b";
export var disabledButtonBg = "#94beda";
export var dropdownActiveBackground = "#dff2f7";
export var errorBackground = "#fee";
export var errorLinkColor = "#801313";
export var errorTextColor = "#a02323";
export var estateRect = "revenue-trends-line-chart-module--estateRect--6ff7f";
export var estateRectTooltip = "revenue-trends-line-chart-module--estateRectTooltip--bf430";
export var exciseRect = "revenue-trends-line-chart-module--exciseRect--8b36b";
export var exciseRectTooltip = "revenue-trends-line-chart-module--exciseRectTooltip--4bbc8";
export var focus = "#2b71b8";
export var fontBodyCopy = "#666";
export var fontSize_10 = ".625rem";
export var fontSize_12 = ".75rem";
export var fontSize_13 = ".8125rem";
export var fontSize_14 = ".875rem";
export var fontSize_15 = ".9375rem";
export var fontSize_16 = "1rem";
export var fontSize_18 = "1.125rem";
export var fontSize_20 = "1.25rem";
export var fontSize_22 = "1.375rem";
export var fontSize_24 = "1.5rem";
export var fontSize_28 = "1.75rem";
export var fontSize_32 = "2rem";
export var fontSize_36 = "2.25rem";
export var fontSize_40 = "2.5rem";
export var fontSize_48 = "3rem";
export var fontSize_56 = "3.5rem";
export var fontTitle = "#555";
export var helpTextColor = "#d93b36";
export var indvRect = "revenue-trends-line-chart-module--indvRect--8a524";
export var indvRectTooltip = "revenue-trends-line-chart-module--indvRectTooltip--3ce61";
export var infoBoxBackground = "#f3fafc";
export var infoBoxBorder = "#bce8f1";
export var infoIcon = "#00a5d4";
export var legendColumn = "revenue-trends-line-chart-module--legendColumn--62af4";
export var legendContainer = "revenue-trends-line-chart-module--legendContainer--c53ef";
export var legendElement = "revenue-trends-line-chart-module--legendElement--1da6f";
export var legendText = "revenue-trends-line-chart-module--legendText--7a05d";
export var lightWeight = "300";
export var lineChart = "revenue-trends-line-chart-module--lineChart--7b27b";
export var linkColor = "#2272ce";
export var mainWidth = "1140px";
export var miscRect = "revenue-trends-line-chart-module--miscRect--d80f0";
export var miscRectTooltip = "revenue-trends-line-chart-module--miscRectTooltip--4a6d7";
export var paleBg = "#f8f8f8";
export var placeholder = "#a8a8a8";
export var primary = "#0071bc";
export var primaryDarker = "#205493";
export var primaryGlass = "rgba(0,113,188,.3)";
export var primaryInactive = "rgba(8,114,185,.4)";
export var primaryLightBackground = "#a9ddf3";
export var primaryPale = "#f1f7f8";
export var semiBoldWeight = "600";
export var siteContentWidth = "1140px";
export var slateBlue = "#112e51";
export var socialSecRect = "revenue-trends-line-chart-module--socialSecRect--8c7c2";
export var socialSecRectTooltip = "revenue-trends-line-chart-module--socialSecRectTooltip--343d6";
export var tooltipColumn = "revenue-trends-line-chart-module--tooltipColumn--98d6b";
export var tooltipContainer = "revenue-trends-line-chart-module--tooltipContainer--ef7a4";
export var tooltipItem = "revenue-trends-line-chart-module--tooltipItem--81bb5";
export var tooltipItemCategory = "revenue-trends-line-chart-module--tooltipItemCategory--6630c";
export var tooltipItemText = "revenue-trends-line-chart-module--tooltipItemText--11342";
export var tooltipYearHeader = "revenue-trends-line-chart-module--tooltipYearHeader--f699f";
// extracted by mini-css-extract-plugin
export var altButtonActiveBg = "#eff4fb";
export var animationDuration = "1000ms";
export var apiHeader = "#f6f6f6";
export var basicWarningBackground = "#fdfdec";
export var basicWarningBorder = "#edf079";
export var bodyBackground = "#f1f1f1";
export var boldWeight = "800";
export var borderColor = "#ddd";
export var breadCrumbsContainer = "sources-of-federal-revenue-module--breadCrumbsContainer--369bb";
export var breakpointLg = "992px";
export var breakpointMd = "768px";
export var breakpointSm = "600px";
export var breakpointXl = "1205px";
export var cardBody = "#555";
export var chartBackdrop = "sources-of-federal-revenue-module--chartBackdrop--51f0d";
export var contentContainer = "sources-of-federal-revenue-module--contentContainer--04d7f";
export var contentSectionBackground = "#fff";
export var darkGreen = "#2e8540";
export var darkGreyBorder = "#979797";
export var darkPink = "#d72d79";
export var darkSlateBlue = "#112e51";
export var ddBorderColor = "#d6d7d9";
export var debtExplainerLightSecondary = "#b699c6";
export var debtExplainerPrimary = "#4a0072";
export var debtExplainerSecondary = "#3b005b";
export var desktopSubNav = "sources-of-federal-revenue-module--desktopSubNav--06f90";
export var disabledButtonBg = "#94beda";
export var dropdownActiveBackground = "#dff2f7";
export var errorBackground = "#fee";
export var errorLinkColor = "#801313";
export var errorTextColor = "#a02323";
export var focus = "#2b71b8";
export var fontBodyCopy = "#666";
export var fontSize_10 = ".625rem";
export var fontSize_12 = ".75rem";
export var fontSize_13 = ".8125rem";
export var fontSize_14 = ".875rem";
export var fontSize_15 = ".9375rem";
export var fontSize_16 = "1rem";
export var fontSize_18 = "1.125rem";
export var fontSize_20 = "1.25rem";
export var fontSize_22 = "1.375rem";
export var fontSize_24 = "1.5rem";
export var fontSize_28 = "1.75rem";
export var fontSize_32 = "2rem";
export var fontSize_36 = "2.25rem";
export var fontSize_40 = "2.5rem";
export var fontSize_48 = "3rem";
export var fontSize_56 = "3.5rem";
export var fontTitle = "#555";
export var helpTextColor = "#d93b36";
export var infoBoxBackground = "#f3fafc";
export var infoBoxBorder = "#bce8f1";
export var infoIcon = "#00a5d4";
export var lightWeight = "300";
export var linkColor = "#2272ce";
export var mainContainer = "sources-of-federal-revenue-module--mainContainer--1c052";
export var mainContent = "sources-of-federal-revenue-module--mainContent--66bb7";
export var mainWidth = "1140px";
export var mobileSubNav = "sources-of-federal-revenue-module--mobileSubNav--835f4";
export var paleBg = "#f8f8f8";
export var placeholder = "#a8a8a8";
export var primary = "#0071bc";
export var primaryDarker = "#205493";
export var primaryGlass = "rgba(0,113,188,.3)";
export var primaryInactive = "rgba(8,114,185,.4)";
export var primaryLightBackground = "#a9ddf3";
export var primaryPale = "#f1f7f8";
export var quoteBoxContent = "sources-of-federal-revenue-module--quoteBoxContent--48c10";
export var relatedDatasets = "sources-of-federal-revenue-module--relatedDatasets--ab09c";
export var relatedDatasetsStyle = "sources-of-federal-revenue-module--relatedDatasetsStyle--3b4da";
export var section = "sources-of-federal-revenue-module--section--fb4e8";
export var sectionBorder = "sources-of-federal-revenue-module--sectionBorder--73d56";
export var sectionHeading = "sources-of-federal-revenue-module--sectionHeading--ad803";
export var semiBoldWeight = "600";
export var siteContentWidth = "1140px";
export var slateBlue = "#112e51";
export var socialShare = "sources-of-federal-revenue-module--socialShare--837f4";
export var socialShareContainer = "sources-of-federal-revenue-module--socialShareContainer--a7f1f";
export var sourceURL = "sources-of-federal-revenue-module--sourceURL--ddf85";
export var sourcesContent = "sources-of-federal-revenue-module--sourcesContent--00658";
export var visWithCallout = "sources-of-federal-revenue-module--visWithCallout--778e7";
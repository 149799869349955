// extracted by mini-css-extract-plugin
export var bondTitle = "types-of-savings-bonds-responsive-module--bondTitle--e1258";
export var columnContainer = "types-of-savings-bonds-responsive-module--columnContainer--ee649";
export var contentBox = "types-of-savings-bonds-responsive-module--contentBox--51eb6";
export var gradient = "types-of-savings-bonds-responsive-module--gradient--e0932";
export var headerBox = "types-of-savings-bonds-responsive-module--headerBox--dfcb8";
export var scrollContainer = "types-of-savings-bonds-responsive-module--scrollContainer--e60ff";
export var shadow = "types-of-savings-bonds-responsive-module--shadow--08791";
export var singular = "types-of-savings-bonds-responsive-module--singular--5815c";
export var tableContainer = "types-of-savings-bonds-responsive-module--tableContainer--2c6ec";
export var typeBox = "types-of-savings-bonds-responsive-module--typeBox--606b4";
export var typeContainer = "types-of-savings-bonds-responsive-module--typeContainer--22c41";
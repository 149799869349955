// extracted by mini-css-extract-plugin
export var altButtonActiveBg = "#eff4fb";
export var animationDuration = "1000ms";
export var apiHeader = "#f6f6f6";
export var basicWarningBackground = "#fdfdec";
export var basicWarningBorder = "#edf079";
export var bodyBackground = "#f1f1f1";
export var boldWeight = "800";
export var borderColor = "#ddd";
export var breadCrumbsContainer = "savings-bonds-sold-by-type-chart-module--breadCrumbsContainer--6850d";
export var breakpointLg = "992px";
export var breakpointMd = "768px";
export var breakpointSm = "600px";
export var breakpointXl = "1205px";
export var cardBody = "#555";
export var chartBackdrop = "savings-bonds-sold-by-type-chart-module--chartBackdrop--f90a4";
export var chartContainer = "savings-bonds-sold-by-type-chart-module--chartContainer--cea3a";
export var chartStyle = "savings-bonds-sold-by-type-chart-module--chartStyle--e032c";
export var contentContainer = "savings-bonds-sold-by-type-chart-module--contentContainer--94ac7";
export var contentSectionBackground = "#fff";
export var darkGreen = "#2e8540";
export var darkGreyBorder = "#979797";
export var darkPink = "#d72d79";
export var darkSlateBlue = "#112e51";
export var dataHeader = "savings-bonds-sold-by-type-chart-module--dataHeader--f44a0";
export var ddBorderColor = "#d6d7d9";
export var debtExplainerLightSecondary = "#b699c6";
export var debtExplainerPrimary = "#4a0072";
export var debtExplainerSecondary = "#3b005b";
export var desktopSubNav = "savings-bonds-sold-by-type-chart-module--desktopSubNav--8e4c4";
export var disabledButtonBg = "#94beda";
export var dropdownActiveBackground = "#dff2f7";
export var errorBackground = "#fee";
export var errorLinkColor = "#801313";
export var errorTextColor = "#a02323";
export var focus = "#2b71b8";
export var fontBodyCopy = "#666";
export var fontSize_10 = ".625rem";
export var fontSize_12 = ".75rem";
export var fontSize_13 = ".8125rem";
export var fontSize_14 = ".875rem";
export var fontSize_15 = ".9375rem";
export var fontSize_16 = "1rem";
export var fontSize_18 = "1.125rem";
export var fontSize_20 = "1.25rem";
export var fontSize_22 = "1.375rem";
export var fontSize_24 = "1.5rem";
export var fontSize_28 = "1.75rem";
export var fontSize_32 = "2rem";
export var fontSize_36 = "2.25rem";
export var fontSize_40 = "2.5rem";
export var fontSize_48 = "3rem";
export var fontSize_56 = "3.5rem";
export var fontTitle = "#555";
export var helpTextColor = "#d93b36";
export var inflationLabel = "savings-bonds-sold-by-type-chart-module--inflationLabel--596ea";
export var inflationToggleContainer = "savings-bonds-sold-by-type-chart-module--inflationToggleContainer--05501";
export var infoBoxBackground = "#f3fafc";
export var infoBoxBorder = "#bce8f1";
export var infoIcon = "#00a5d4";
export var infoTipContainer = "savings-bonds-sold-by-type-chart-module--infoTipContainer--747cf";
export var lightWeight = "300";
export var linkColor = "#2272ce";
export var mainContainer = "savings-bonds-sold-by-type-chart-module--mainContainer--22332";
export var mainContent = "savings-bonds-sold-by-type-chart-module--mainContent--7da43";
export var mainWidth = "1140px";
export var mobileSubNav = "savings-bonds-sold-by-type-chart-module--mobileSubNav--9700d";
export var mudAccordion = "savings-bonds-sold-by-type-chart-module--mudAccordion--b52a2";
export var paleBg = "#f8f8f8";
export var placeholder = "#a8a8a8";
export var postQuoteBoxAccordionContainer = "savings-bonds-sold-by-type-chart-module--postQuoteBoxAccordionContainer--9177b";
export var primary = "#0071bc";
export var primaryDarker = "#205493";
export var primaryGlass = "rgba(0,113,188,.3)";
export var primaryInactive = "rgba(8,114,185,.4)";
export var primaryLightBackground = "#a9ddf3";
export var primaryPale = "#f1f7f8";
export var quoteBoxContent = "savings-bonds-sold-by-type-chart-module--quoteBoxContent--d5c79";
export var relatedDatasets = "savings-bonds-sold-by-type-chart-module--relatedDatasets--07d36";
export var relatedDatasetsStyle = "savings-bonds-sold-by-type-chart-module--relatedDatasetsStyle--a7e5d";
export var section = "savings-bonds-sold-by-type-chart-module--section--a498f";
export var sectionBorder = "savings-bonds-sold-by-type-chart-module--sectionBorder--a9227";
export var sectionHeading = "savings-bonds-sold-by-type-chart-module--sectionHeading--79979";
export var semiBoldWeight = "600";
export var siteContentWidth = "1140px";
export var slateBlue = "#112e51";
export var socialShare = "savings-bonds-sold-by-type-chart-module--socialShare--f3d17";
export var socialShareContainer = "savings-bonds-sold-by-type-chart-module--socialShareContainer--673de";
export var sourceURL = "savings-bonds-sold-by-type-chart-module--sourceURL--61b07";
export var treasurySavingsBondsExplainerHero = "#b699c6";
export var treasurySavingsBondsExplainerLightSecondary = "#f8f0f9";
export var treasurySavingsBondsExplainerPrimary = "#3b005b";
export var treasurySavingsBondsExplainerSecondary = "#b04abd";
export var visWithCallout = "savings-bonds-sold-by-type-chart-module--visWithCallout--5d2de";